import React from 'react';
import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import 'react-circular-progressbar/dist/styles.css';
import './Gallery7.css'
import '../../styles/style.css'

//compressed images
import thumbnail1 from './thumbnails/1_valentine-min.jpg'
import thumbnail2 from './thumbnails/2_valentine-min.jpg'
import thumbnail3 from './thumbnails/3_crazy_heart-min.jpg'
import thumbnail4 from './thumbnails/4_her-min.jpg'
import thumbnail5 from './thumbnails/5_cactus-min.jpg'
import thumbnail6 from './thumbnails/6_sea_creature-min.jpg'
import thumbnail7 from './thumbnails/7_trucking-min.jpg'
import thumbnail8 from './thumbnails/8_coral-min.jpg'
import thumbnail9 from './thumbnails/9_freebird-min.jpg'
import thumbnail10 from './thumbnails/10_valentine_min.jpg'
import thumbnail11 from './thumbnails/11_red_heart.jpg'
import thumbnail12 from './thumbnails/12_small_heart.jpg'
import thumbnail13 from './thumbnails/20_military-main-min.jpg'
import thumbnail14 from './thumbnails/21_end_of_time-min.jpg'

import related11 from './related_thumbs/11_red_heart_side.jpg'
import related_13_1 from './related_thumbs/20.1_military-side-1-min.jpg'
import related_13_2 from './related_thumbs/20.2_military-side-2-min.jpg'
import related_13_3 from './related_thumbs/20.3_military-side-3-min.jpg'
import related_13_4 from './related_thumbs/20.4_military-side-4-min.jpg'
import related_14_1 from './related_thumbs/21.1_end_of_time-min.jpg'
import related_14_2 from './related_thumbs/21.2_end_of_time-min.jpg'
import related_14_3 from './related_thumbs/21.3_end_of_time-min.jpg'
import related_14_4 from './related_thumbs/21.4_end_of_time-min.jpg'

function GallerySeven() {


    const [relatedImage, setRelatedImages] = useState([]);
    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('Valentine 1');
    const [displayImageSize, setDisplayImageSize] = useState('16" x 8" x 2"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Stainless Steel On Marble Base');
    const [fullScreen, setFullScreen] = useState(false);

    const relatedImages1 = []
    const relatedImages2 = []
    const relatedImages3 = []
    const relatedImages4 = []
    const relatedImages5 = []
    const relatedImages6 = []
    const relatedImages7 = []
    const relatedImages8 = []
    const relatedImages9 = []
    const relatedImages10 = []
    const relatedImages11 = [related11]
    const relatedImages12 = []
    const relatedImages13 = [related_13_1, related_13_2, related_13_3, related_13_4]
    const relatedImages14 = [related_14_1, related_14_2, related_14_3, related_14_4]

    useEffect(() => {
        const timer = setTimeout(() => {
            const progressBar = document.querySelector(".loadingContainer");
            const mainImageGrid = document.querySelector(".mainGallerySevenContainer");
            if (progressBar) progressBar.style.display = "none";
            if (mainImageGrid) mainImageGrid.style.display = "block";
        }, 1200);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)
    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src


        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Valentine 1')
                setDisplayImageSize('43" x 44" x 6"')
                setDisplayImageMaterial('Stainless Steel On Marble Base')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Valentine 2')
                setDisplayImageSize('16" x 8" x 2"')
                setDisplayImageMaterial('Stainless Steel On Marble Base')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Crazy Heart Valentine')
                setDisplayImageSize('11" x 4" x 2.5"')
                setDisplayImageMaterial('Stainless Steel On Marble Base')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Her')
                setDisplayImageSize('21" x 17" x 4"')
                setDisplayImageMaterial('Stainless Steel On Marble Base')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Creature with Cactus')
                setDisplayImageSize('12" x 5" x 7"')
                setDisplayImageMaterial('Aluminum')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('Sea Creature')
                setDisplayImageSize('12" x 7.5" x 3"')
                setDisplayImageMaterial('Aluminum')
                break;
            case '7':
                console.log('came to image 7')
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('Keep on Truckin')
                setDisplayImageSize('86" x 19" x 4"')
                setDisplayImageMaterial('86" x 19" x 4"')
                // const mainImage = document.getElementsByClassName('displayImageContainer')
                // mainImage[0].style.display = 'block'
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('Coral')
                setDisplayImageSize('10" x 18" x 8"')
                setDisplayImageMaterial('Steel, Brass, Stone')
                break;
            case '9':
                setRelatedImages(relatedImages9)
                setCurrentDisplayImage(thumbnail9)
                setMainImageOnDisplay(9)
                setDisplayImageHeading('Freebirds with Snake')
                setDisplayImageSize('108" x 22" x 18"')
                setDisplayImageMaterial('Stainless Steel and Wood')
                break;
            case '10':
                setRelatedImages(relatedImages10)
                setCurrentDisplayImage(thumbnail10)
                setMainImageOnDisplay(10)
                setDisplayImageHeading('Valentine 3')
                setDisplayImageSize('43" x 44" x 6')
                setDisplayImageMaterial('Stainless Steel and Copper')
                break;
            case '11':
                setRelatedImages(relatedImages11)
                setCurrentDisplayImage(thumbnail11)
                setMainImageOnDisplay(11)
                setDisplayImageHeading('Red Heart')
                setDisplayImageSize('14" x 6" x 3"')
                setDisplayImageMaterial('Formed Stainless Steel on Walnut base')
                break;
            case '12':
                setRelatedImages(relatedImages12)
                setCurrentDisplayImage(thumbnail12)
                setMainImageOnDisplay(12)
                setDisplayImageHeading('Heart Polished')
                setDisplayImageSize('8" x 3" x 2"')
                setDisplayImageMaterial('Formed and Polished Stainless steel')
                break;
            case '13':
                setRelatedImages(relatedImages13)
                setCurrentDisplayImage(thumbnail13)
                setMainImageOnDisplay(13)
                setDisplayImageHeading('Military Industrial Complex')
                setDisplayImageSize('8" x 8" x 6"')
                setDisplayImageMaterial('Steel, Stainless Steel, Resin')
                break;
            case '14':
                setRelatedImages(relatedImages14)
                setCurrentDisplayImage(thumbnail14)
                setMainImageOnDisplay(14)
                setDisplayImageHeading('Contemplating the End Of Time')
                setDisplayImageSize('9" x 9" x 8.5"')
                setDisplayImageMaterial('Steel, Brass, Copper, Resin')
                break;
            default:
                setRelatedImages([])
                break;
        }
    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return (

        <div className="gallerySeven">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    pathTransitionDuration: 0.5,
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>
            {/* <LoadingContainer></LoadingContainer> */}

            {/*This is for DESKTOP VIEW*/}
            <div className='mainGallerySevenContainer'>
                <div className='gallerySevenContainer'>
                    <div className='firstContainer'>
                        <div className='row-tiny'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} onC src={thumbnail1} alt='gallery7'></img>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='gallery7'></img>
                            <img className='imageCard' id='10' onMouseOver={(e) => updateImage(e)} src={thumbnail10} alt='gallery7'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='gallery7'></img>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} src={thumbnail4} alt='gallery7'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='gallery7'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} src={thumbnail6} alt='gallery7'></img>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} src={thumbnail8} alt='gallery7'></img>
                            <img className='imageCard' id='11' onMouseOver={(e) => updateImage(e)} src={thumbnail11} alt='gallery7'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='12' onMouseOver={(e) => updateImage(e)} src={thumbnail12} alt='gallery7'></img>
                            <img className='imageCard' id='13' onMouseOver={(e) => updateImage(e)} src={thumbnail13} alt='gallery7'></img>
                            <img className='imageCard' id='14' onMouseOver={(e) => updateImage(e)} src={thumbnail14} alt='gallery7'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>

                        <div className='displayImageContainer'>
                            <div className='displayImageOne'>
                                <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                            </div>
                            <div className='displayImageTwo'>
                                <div className='related_images'>
                                    {relatedImage.map((url, index) => {
                                        return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                    })}
                                </div>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <h6 id='display_image_size'>{displayImageSize}</h6>
                                    <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_10' src={thumbnail10} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_13' src={thumbnail13} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail8} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                    <img className='imageCardMobile' id='mobile_11' src={thumbnail11} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_14' src={thumbnail14} onClick={(e) => openFullScreenMobile(e)} alt='gallery7'></img>
                </div>
            </div>

        </div>

    )

}

export default GallerySeven;
