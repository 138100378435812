import React from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './Gallery2.css'
import '../../styles/style.css'
import 'react-circular-progressbar/dist/styles.css';

//thumbnails
import thumbnail1 from './thumbnails/1_goddess-min.jpg'
import thumbnail2 from './thumbnails/2_chaos-min.jpg'
import thumbnail3 from './thumbnails/3_head-min.jpg'
import thumbnail4 from './thumbnails/4_man_woman_blue-min.jpg'
import thumbnail5 from './thumbnails/5_borken_ass-min.jpg'
import thumbnail6 from './thumbnails/6_emergence-min.jpg'
import thumbnail7 from './thumbnails/7_hooded_man-min.jpg'
import thumbnail8 from './thumbnails/8_american_dream.png'
import thumbnail9 from './thumbnails/9_falling_man-min.jpg'
import thumbnail10 from './thumbnails/10_thin_man_stainless-min.jpg'
import thumbnail11 from './thumbnails/11_thin_main_forged-min.jpg'
import thumbnail12 from './thumbnails/12_thin_man_woman-min.jpg'
import thumbnail13 from './thumbnails/13_optism-min.jpg'
import thumbnail14 from './thumbnails/14_boy-min.jpg'
import thumbnail15 from './thumbnails/15_slouching_bethlehem-min.jpg'
import thumbnail16 from './thumbnails/16_man_woman.jpg'
import thumbnail17 from './thumbnails/17_woman_dreams.jpg'
import thumbnail18 from './thumbnails/18_main.jpg'
import thumbnail19 from './thumbnails/19_broken_ass.jpeg'

//these are related thumbnails
import relatedthumbnail1 from './related_thumbs/1.1_goddess_side-min.jpg'
import relatedthumbnail3 from './related_thumbs/3.1_head_LR_front-min.jpg'
import relatedthumbnail3_1 from './related_thumbs/3.2_head_LR_side-min.jpg'
import relatedthumbnail4 from './related_thumbs/4.1_man_woman_blue_close-min.jpg'
import relatedthumbnail5 from './related_thumbs/5.1_broken_ass-min.jpg'
import relatedthumbnail6 from './related_thumbs/6.1_emergence_side-min.jpg'
import relatedthumbnail7 from './related_thumbs/7_1_hooded_alt.jpg'
import relatedthumbnail7_1 from './related_thumbs/7_2_hooded_alt.jpg'
import relatedthumbnail9 from './related_thumbs/9.1_falling_man-min.jpg'
import relatedthumbnail9_1 from './related_thumbs/9.2_falling_man-min.jpg'
import relatedthumbnail10 from './related_thumbs/10.1_thin_man_stainless_zoom-min.jpg'
import relatedthumbnail11 from './related_thumbs/11.1_thin_main_forged_face-min.jpg'
import relatedthumbnail11_1 from './related_thumbs/11.2_thin_main_forged_face-min.jpg'
import relatedthumbnail14 from './related_thumbs/14_1_boy-min.jpg'
import relatedthumbnail15 from './related_thumbs/15.1_slouching_bethlehem-min.jpg'
import relatedthumbnail15_1 from './related_thumbs/15.2_slouching_bethlehem-min.jpg'
import relatedthumbnail16 from './related_thumbs/16_1_man_woman_related.jpg'
import relatedthumbnail17 from './related_thumbs/17_1_woman_dream.jpeg'
import relatedthumbnail17_1 from './related_thumbs/17_2_woman_Dream.jpeg'
import related18_1 from './related_thumbs/18_1_extra.jpg'
import related18_2 from './related_thumbs/18_2_extra.jpg'
import related18_3 from './related_thumbs/18_3_extra.jpg'
import related18_4 from './related_thumbs/18_4_extra.jpg'
const relatedImages19 = []

function GalleryTwo() {

    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('Goddess');
    const [displayImageSize, setDisplayImageSize] = useState('24" x 16" x 12"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Stainless Steel, Acrylic, Copper');
    const [fullScreen, setFullScreen] = useState(false);

    const relatedImages1 = [relatedthumbnail1]
    const relatedImages2 = []
    const relatedImages3 = [relatedthumbnail3, relatedthumbnail3_1]
    const relatedImages4 = [relatedthumbnail4]
    const relatedImages5 = [relatedthumbnail5]
    const relatedImages6 = [relatedthumbnail6]
    const relatedImages7 = [relatedthumbnail7, relatedthumbnail7_1]
    const relatedImages8 = []
    const relatedImages9 = [relatedthumbnail9, relatedthumbnail9_1]
    const relatedImages10 = [relatedthumbnail10]
    const relatedImages11 = [relatedthumbnail11, relatedthumbnail11_1]
    const relatedImages12 = []
    const relatedImages13 = []
    const relatedImages14 = [relatedthumbnail14]
    const relatedImages15 = [relatedthumbnail15, relatedthumbnail15_1]
    const relatedImages16 = [relatedthumbnail16]
    const relatedImages17 = [relatedthumbnail17, relatedthumbnail17_1]
    const relatedImages18 = [related18_1, related18_4, related18_3, related18_2]

    const [showLandscape, setShowLandscape] = useState(false);
    const [relatedImage, setRelatedImages] = useState(relatedImages1);

    useEffect(() => {
        const timer = setTimeout(() => {
            const progressBar = document.querySelector(".loadingContainer");
            const mainImageGrid = document.querySelector(".mainGalleryTwoContainer");
            if (progressBar) progressBar.style.display = "none";
            if (mainImageGrid) mainImageGrid.style.display = "block";
        }, 1200);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    /*jslint eqeq: true*/
    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)
        /*jslint eqeq: true*/
        if (mainImageOnDisplay == 1 && element.target.id == 0) {
            setDisplayImageHeading('Goddess (Side View)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 3 && element.target.id == 0) {
            setDisplayImageHeading('Head (3/4 View Close Up)')
            setShowLandscape(false)

        }
        else if (mainImageOnDisplay == 3 && element.target.id == 1) {
            setDisplayImageHeading('Head (Left Side View)')
            setShowLandscape(false)

        }
        else if (mainImageOnDisplay == 4 && element.target.id == 0) {
            setDisplayImageHeading('Man Women Blue (Close Up)')
            setShowLandscape(false)

        }
        else if (mainImageOnDisplay == 5 && element.target.id == 0) {
            setDisplayImageHeading('She Broke Her Ass For You (Close Up)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 6 && element.target.id == 0) {
            setDisplayImageHeading('Emergence (Side View)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 16 && element.target.id == 0) {
            setDisplayImageHeading('Man Woman (Close Up)')
            setShowLandscape(false)
        }

    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src
        setShowLandscape(false)




        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Goddess')
                setDisplayImageSize('24" x 16" x 12"')
                setDisplayImageMaterial('Stainless Steel, Acrylic, Copper')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Chaos - Bust')
                setDisplayImageSize('18" x 18" x 24"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Head (Right Side View)')
                setDisplayImageSize('26" x 15" x 15"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Man Women Blue')
                setDisplayImageSize('15" x 32" x 61"')
                setDisplayImageMaterial('Powder Coated Steel, Granite Base')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('She Broke Her Ass For You')
                setDisplayImageSize('10" x 3" x 2.5"')
                setDisplayImageMaterial('Various Materials')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('Emergence')
                setDisplayImageSize('11" x 7" x 28.5"')
                setDisplayImageMaterial('Steel, Aluminium')
                break;
            case '7':
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('Hooded Man - Abu Grahib')
                setDisplayImageSize('45" x 33" x 9"')
                setDisplayImageMaterial('Steel, Fabric, Corrugated Paper')
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('American Dream')
                setDisplayImageSize('36" x 11" x 23"')
                setDisplayImageMaterial('Stainless Steel, Aluminium, Epoxy')
                break;
            case '9':
                setRelatedImages(relatedImages9)
                setCurrentDisplayImage(thumbnail9)
                setMainImageOnDisplay(9)
                setDisplayImageHeading('On The Edge')
                setDisplayImageSize('18" x 10" x 8"')
                setDisplayImageMaterial('Steel, Aluminum, Resins, Stone and Paint')
                break;
            case '10':
                setRelatedImages(relatedImages10)
                setCurrentDisplayImage(thumbnail10)
                setMainImageOnDisplay(10)
                setDisplayImageHeading('Thin Man Stainless')
                setDisplayImageSize('36" X 6" 3"')
                setDisplayImageMaterial('Forgned Stainless Steel')
                break;
            case '11':
                console.log("came to 11")
                setRelatedImages(relatedImages11)
                setCurrentDisplayImage(thumbnail11)
                setMainImageOnDisplay(11)
                setDisplayImageHeading('Who do you think you are?')
                setDisplayImageSize('19" x 2.5" x 2.5"')
                setDisplayImageMaterial('Forged and Torched Steel')
                break;
            case '12':
                console.log("came to 12")
                setRelatedImages(relatedImages12)
                // setCurrentDisplayImage(thumbnail12)
                // setMainImageOnDisplay(12)
                setDisplayImageHeading('Thin Man & Woman')
                setDisplayImageSize('18" x 2" x 2"')
                setDisplayImageMaterial('Steel, Flame cut, Brass finish')
                break;
            case '13':
                console.log("came to 13")
                setRelatedImages(relatedImages13)
                setCurrentDisplayImage(thumbnail13)
                setMainImageOnDisplay(13)
                setDisplayImageHeading('Optimism')
                setDisplayImageSize('33.5" x 11.5" x 2"')
                setDisplayImageMaterial('Stainless Steel, Brush Finish')
                break;
            case '14':
                setRelatedImages(relatedImages14)
                setCurrentDisplayImage(thumbnail14)
                setMainImageOnDisplay(14)
                setDisplayImageHeading('Boy')
                setDisplayImageSize('45" x 1" x 6"')
                setDisplayImageMaterial('Cast Iron Flame Finish')
                break;
            case '15':
                setRelatedImages(relatedImages15)
                setCurrentDisplayImage(thumbnail15)
                setMainImageOnDisplay(15)
                setDisplayImageHeading('Slouching Towards Bethlehem')
                setDisplayImageSize('32" x 19" x 14"')
                setDisplayImageMaterial('Heavy Aluminium on wire armature')
                break;
            case '16':
                setRelatedImages(relatedImages16)
                setCurrentDisplayImage(thumbnail16)
                setMainImageOnDisplay(16)
                setDisplayImageHeading('Man Woman')
                setDisplayImageMaterial('6" x 6" x 37"')
                setDisplayImageMaterial('Steel, Acid Etched, Gold Vinyl')
                break;
            case '17':
                setRelatedImages(relatedImages17)
                setCurrentDisplayImage(thumbnail17)
                setMainImageOnDisplay(17)
                setDisplayImageHeading('A Woman Dreams')
                setDisplayImageSize('34" x 12" x 15"')
                setDisplayImageMaterial('Aluminum, Steel, Clay, Expoxy')
                break;
            case '18':
                setRelatedImages(relatedImages18)
                setCurrentDisplayImage(thumbnail18)
                setMainImageOnDisplay(18)
                setDisplayImageHeading('Family')
                setDisplayImageSize('22" x 8" x 4"')
                setDisplayImageMaterial('Steel on marble base')
                break;
            case '19':
                setRelatedImages(relatedImages19)
                setCurrentDisplayImage(thumbnail19)
                setMainImageOnDisplay(19)
                setDisplayImageHeading('She Broke Her Ass For You 2')
                setDisplayImageSize('10” x 3” x 2.5"')
                setDisplayImageMaterial('Various materials')
                break;
            default:
                break;
        }
    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return <div>
        <div className="galleryTwo">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>


            {/*This is for DESKTOP VIEW*/}
            <div className='mainGalleryTwoContainer'>
                <div className='galleryTwoContainer'>
                    <div className='firstContainer'>
                        <div className='row-tiny'>
                            <img className='imageCard' id='10' onMouseOver={(e) => updateImage(e)} src={thumbnail10} alt='galleryTwo'></img>
                            <img className='imageCard' id='11' onMouseOver={(e) => updateImage(e)} src={thumbnail11} alt='galleryTwo'></img>
                            <img className='imageCard' id='12' onMouseOver={(e) => updateImage(e)} src={thumbnail12} alt='galleryTwo'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} src={thumbnail4} alt='galleryTwo'></img>
                            <img className='imageCard' id='18' onMouseOver={(e) => updateImage(e)} src={thumbnail18} alt='galleryTwo'></img>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} src={thumbnail6} alt='galleryTwo'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} src={thumbnail1} alt='galleryTwo'></img>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='galleryTwo'></img>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='galleryTwo'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='7' onMouseOver={(e) => updateImage(e)} src={thumbnail7} alt='galleryTwo'></img>
                            <img className='imageCard' id='13' onMouseOver={(e) => updateImage(e)} src={thumbnail13} alt='galleryTwo'></img>
                            <img className='imageCard' id='9' onMouseOver={(e) => updateImage(e)} src={thumbnail9} alt='galleryTwo'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} src={thumbnail8} alt='galleryTwo'></img>
                            <img className='imageCard' id='14' onMouseOver={(e) => updateImage(e)} src={thumbnail14} alt='galleryTwo'></img>
                            <img className='imageCard' id='16' onMouseOver={(e) => updateImage(e)} src={thumbnail16} alt='galleryTwo'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='15' onMouseOver={(e) => updateImage(e)} src={thumbnail15} alt='galleryTwo'></img>
                            <img className='imageCard' id='17' onMouseOver={(e) => updateImage(e)} src={thumbnail17} alt='galleryTwo'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='galleryTwo'></img>
                            <img className='imageCard' id='19' onMouseOver={(e) => updateImage(e)} src={thumbnail19} alt='galleryTwo'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>
                        <div className='displayImageContainer'>
                            <div className='displayImageOne'>
                                <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                            </div>
                            <div className='displayImageTwo'>
                                <div className='related_images'>
                                    {relatedImage.map((url, index) => {
                                        return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                    })}
                                </div>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <h6 id='display_image_size'>{displayImageSize}</h6>
                                    <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_7' src={thumbnail7} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail8} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_9' src={thumbnail9} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_12' src={thumbnail12} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>

                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_10' src={thumbnail10} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_11' src={thumbnail11} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_13' src={thumbnail13} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_14' src={thumbnail14} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_15' src={thumbnail15} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_16' src={thumbnail16} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_17' src={thumbnail17} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_18' src={thumbnail18} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_19' src={thumbnail19} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                </div>
                <div className='mobileColumn'>
                </div>
            </div>
        </div>
    </div>;
}

export default GalleryTwo;
