import React from 'react'
import { useState, useEffect } from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import './Gallery1.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';

//these are thumbnails
import thumbnail1 from './thumbnails/1_essence-min.jpg'
import thumbnail2 from './thumbnails/2_skyline_911-min.jpg'
import thumbnail3 from './thumbnails/3_skyline-min.jpg'
import thumbnail4 from './thumbnails/4_spiral-min.jpg'
import thumbnail5 from './thumbnails/5_spire-min.jpg'
import thumbnail6 from './thumbnails/6_skyline_remains-min.jpg'
import thumbnail7 from './thumbnails/7_spiral.jpg'
import thumbnail8 from './thumbnails/20_military-main-min.jpg'

//these are related thumbnails
import relatedthumbnail1 from './related_thumbs/1.1_essence_side-min.jpg'
import relatedthumbnail2 from './related_thumbs/2_skyline_911_side-min.jpg'
import relatedthumbnail2_1 from './related_thumbs/2_1_skyline_side.jpg'
import relatedthumbnail3 from './related_thumbs/3_skyline_911_side-min.jpg'
import relatedthumbnail3_1 from './related_thumbs/3.1_skyline_911_side-min.jpg'
import relatedthumbnail4 from './related_thumbs/4_spiral_zoom-min.jpg'
import relatedthumbnail6 from './related_thumbs/5_skyline_remains_base-min.jpg'
import relatedthumbnail8_1 from './related_thumbs/20.1_military-side-1-min.jpg'
import relatedthumbnail8_2 from './related_thumbs/20.2_military-side-2-min.jpg'
import relatedthumbnail8_3 from './related_thumbs/20.3_military-side-3-min.jpg'
import relatedthumbnail8_4 from './related_thumbs/20.4_military-side-4-min.jpg'

function GalleryOne() {

    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('Essence');
    const [displayImageSize, setDisplayImageSize] = useState('24" x 12" x 12"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Stainless Steel, Steel, Granite, Paint');
    const [fullScreen, setFullScreen] = useState(false);

    const relatedImages1 = [relatedthumbnail1]
    const relatedImages2 = [relatedthumbnail2, relatedthumbnail2_1]
    const relatedImages3 = [relatedthumbnail3, relatedthumbnail3_1]
    const relatedImages4 = [relatedthumbnail4]
    const relatedImages5 = []
    const relatedImages6 = [relatedthumbnail6]
    const relatedImages7 = []
    const relatedImages8 = [relatedthumbnail8_1, relatedthumbnail8_2, relatedthumbnail8_3, relatedthumbnail8_4]
    const [showLandscape, setShowLandscape] = useState(false);
    const [relatedImage, setRelatedImages] = useState(relatedImages1);

    useEffect(() => {
        const timer = setTimeout(() => {
            const progressBar = document.querySelector(".loadingContainer");
            const mainImageGrid = document.querySelector(".mainGalleryOneContainer");
            if (progressBar) progressBar.style.display = "none";
            if (mainImageGrid) mainImageGrid.style.display = "block";
        }, 1200);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)

        if (mainImageOnDisplay == 1 && element.target.id == 0) {
            setDisplayImageHeading('Essence (Close Up)')

        }
        else if (mainImageOnDisplay == 4 && element.target.id == 0) {
            setDisplayImageHeading('Spiral Wave (Close Up)')
            setCurrentDisplayImage(relatedthumbnail4)

        }
        else if (mainImageOnDisplay == 6 && element.target.id == 0) {
            setDisplayImageHeading('911 Skyline Remains (Base)')
        }

    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src
        setShowLandscape(false)

        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Essence')
                setDisplayImageSize('24" x 12" x 12"')
                setDisplayImageMaterial('Stainless Steel, Steel, Granite, Paint')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Skyline 911')
                setDisplayImageSize('23" x 16" x 48"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Skyline 911')
                setDisplayImageSize('18.5" x 40" x 9.5"')
                setDisplayImageMaterial('Aluminum')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Spiral Wave')
                setDisplayImageSize('10" x 11" x 6"')
                setDisplayImageMaterial('Steel, Hot Acid Patina')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Spire')
                setDisplayImageSize('9" x 6" x 14"')
                setDisplayImageMaterial('Steel, Patina')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('911 Skyline Remains')
                setDisplayImageSize('34" x 13" x 53"')
                setDisplayImageMaterial('Steel, Stone, Hot Acid Patina')
                break;
            case '7':
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('Spiral')
                setDisplayImageSize('11" x 7" x 10"')
                setDisplayImageMaterial('Steel, Patina')
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('Military Industrial Complex')
                setDisplayImageSize('8" x 8" x 6"')
                setDisplayImageMaterial('Steel, Stainless Steel, Resin')
                break;
            default:
                break;
        }
    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenLandscape(element) {
        const DIV = document.querySelector('#display_image_landscape');
        DIV.requestFullscreen()
    }


    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return (
        <div className="galleryOne">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    pathTransitionDuration: 0.5,
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>


            {/*This is for DESKTOP VIEW*/}
            <div className='mainGalleryOneContainer'>
                <div className='galleryOneContainer'>
                    <div className='firstContainer'>
                        <div className='row'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} src={thumbnail1} alt='galleryOne'></img>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='galleryOne'></img>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='galleryOne'></img>
                        </div>
                        <div className='row'>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} src={thumbnail4} alt='galleryOne'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='galleryOne'></img>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} src={thumbnail6} alt='galleryOne'></img>
                        </div>
                        <div className='row'>
                            <img className='imageCard' id='7' onMouseOver={(e) => updateImage(e)} src={thumbnail7} alt='galleryOne'></img>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} src={thumbnail8} alt='galleryOne'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>
                        {(!showLandscape) &&
                            <div className='displayImageContainer'>
                                <div className='displayImageOne'>
                                    <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                                </div>
                                <div className='displayImageTwo'>
                                    <div className='related_images'>
                                        {relatedImage.map((url, index) => {
                                            return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                        })}
                                    </div>
                                    <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                    <div id='display_image_information'>
                                        <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                        <h6 id='display_image_size'>{displayImageSize}</h6>
                                        <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                    </div>
                                </div>
                            </div>
                        }
                        {(showLandscape) &&
                            <div className='displayImageNonFlex'>
                                <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreenLandscape(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <p id='display_image_size'>{displayImageSize}</p>
                                    <p id='display_image_material'>{displayImageMaterial}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail8} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_7' src={thumbnail7} onClick={(e) => openFullScreenMobile(e)} alt='galleryOne'></img>
                </div>
            </div>
        </div>
    )
}

export default GalleryOne