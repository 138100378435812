import React from 'react';
import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import 'react-circular-progressbar/dist/styles.css';
import './Gallery5.css'
import '../../styles/style.css'

//thumbnails
import thumbnail1 from './thumbnails/1_bronze_dancer-min.jpg'
import thumbnail2 from './thumbnails/2_marble_dancer-min.jpg'
import thumbnail3 from './thumbnails/3_dancer_facing_top-min.jpg'
import thumbnail4 from './thumbnails/4_egyptains_dancing-min.jpg'
import thumbnail5 from './thumbnails/5_two_dancers-min.png'

//these are related thumbnails
import relatedthumbnail1 from './related_thumbs/1.1_bronze_dancer_side-min.jpg'
import relatedthumbnail2 from './related_thumbs/2.1_marble_dancer-min.jpg'
import relatedthumbnail3 from './related_thumbs/3_dancer_facing_out-min.jpg'
import relatedthumbnail5_1 from './related_thumbs/5_1_two_dancers_side.jpg'


function GalleryFive() {

    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('Bronze Dancer - Hazel');
    const [displayImageSize, setDisplayImageSize] = useState('8" x 8" x 35"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Bronze Cast and Polish Finish');
    const relatedImages1 = [relatedthumbnail1]
    const relatedImages2 = [relatedthumbnail2]
    const relatedImages3 = [relatedthumbnail3]
    const relatedImages4 = []
    const relatedImages5 = [relatedthumbnail5_1]
    const [fullScreen, setFullScreen] = useState(false);

    const [showLandscape, setShowLandscape] = useState(false);
    const [relatedImage, setRelatedImages] = useState(relatedImages1);


    useEffect(() => {
        const timer = setTimeout(() => {
            const progressBar = document.querySelector(".loadingContainer");
            const mainImageGrid = document.querySelector(".mainGalleryFiveContainer");
            if (progressBar) progressBar.style.display = "none";
            if (mainImageGrid) mainImageGrid.style.display = "block";
        }, 1200);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);


    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)

        if (mainImageOnDisplay == 1 && element.target.id == 0) {
            setDisplayImageHeading('Bronze Dancer - Hazel (Side View)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 3 && element.target.id == 0) {
            setDisplayImageHeading('Double Dancer (Side View')
            setShowLandscape(false)

        }
        else if (mainImageOnDisplay == 5 && element.target.id == 0) {
            setDisplayImageHeading('Double Dancer Shadowing (Additional View)')
            setShowLandscape(false)
        }
    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src
        setShowLandscape(false)

        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Bronze Dancer - Hazel')
                setDisplayImageSize('8" x 8" x 3.5"')
                setDisplayImageMaterial('Bronze Cast and Polish Finish')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Marble Dancer')
                setDisplayImageSize('19" x 22" x 7/8"')
                setDisplayImageMaterial('Carrerra Marble')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Double Dancer')
                setDisplayImageSize('24" x 48" x 60"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Egyptian Dance')
                setDisplayImageSize('46" x 23" x 3.5"')
                setDisplayImageMaterial('Stainless Steel, Steel')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Two Dancers Shadowing')
                setDisplayImageSize('17" x 21" x 1/8"')
                setDisplayImageMaterial('Aluminum hand brushed finish')
                break;
            default:
                break;
        }
    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenLandscape(element) {
        const DIV = document.querySelector('#display_image_landscape');
        DIV.requestFullscreen()
    }

    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return <div>
        <div className="galleryTwo">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    pathTransitionDuration: 0.5,
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>


            {/*This is for DESKTOP VIEW*/}
            <div className='mainGalleryFiveContainer'>
                <div className='galleryFiveContainer'>
                    <div className='firstContainer'>
                        <div className='row'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} src={thumbnail1} alt='galleryFive'></img>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='galleryFive'></img>
                        </div>
                        <div className='row'>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='galleryFive'></img>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} src={thumbnail4} alt='galleryFive'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='galleryFive'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>
                        {(!showLandscape) &&
                            <div className='displayImageContainer'>
                                <div className='displayImageOne'>
                                    <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                                </div>
                                <div className='displayImageTwo'>
                                    <div className='related_images'>
                                        {relatedImage.map((url, index) => {
                                            return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                        })}
                                    </div>
                                    <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                    <div id='display_image_information'>
                                        <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                        <h6 id='display_image_size'>{displayImageSize}</h6>
                                        <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                    </div>
                                </div>
                            </div>
                        }
                        {(showLandscape) &&
                            <div className='displayImageNonFlex'>
                                <img id='display_image_landscape' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreenLandscape(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <p id='display_image_size'>{displayImageSize}</p>
                                    <p id='display_image_material'>{displayImageMaterial}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='galleryFive'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='galleryFive'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='galleryFive'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='galleryFive'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='galleryFive'></img>
                </div>
            </div>
        </div>
    </div>;
}

export default GalleryFive;
