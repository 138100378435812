import React from 'react'
import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import 'react-circular-progressbar/dist/styles.css';
import './NewWork.css'
import '../../styles/style.css'

//these are compressed images
import thumbnail1 from './thumbnails/1_goddess-min.jpg'
import thumbnail2 from './thumbnails/2_emergence-min.jpg'
import thumbnail3 from './thumbnails/3_essence-min.jpg'
import thumbnail4 from './thumbnails/4_head-min.jpg'
import thumbnail5 from './thumbnails/5_man_woman_blue-min.jpg'
import thumbnail6 from './thumbnails/6_chaos-min.jpg'
import thumbnail7 from './thumbnails/7_coral-min.jpg'
import thumbnail8 from './thumbnails/8_spiralreduced-min.jpg'
import thumbnail9 from './thumbnails/9_man_woman.jpg'
import thumbnail10 from './thumbnails/10_optism-min.jpg'
import thumbnail11 from './thumbnails/11_spiral-min.jpg'
import thumbnail12 from './thumbnails/12_trucking-min.jpg'
import thumbnail13 from './thumbnails/13_sail_dream-min.jpg'
import thumbnail14 from './thumbnails/14_closet.jpg'
import thumbnail15 from './thumbnails/15_thin_main_forged.jpg'
import thumbnail16 from './thumbnails/16_red_heart.jpg'
import thumbnail17 from './thumbnails/17_main.jpg'
import thumbnail18 from './thumbnails/18_red_flower.jpg'
import thumbnail19 from './thumbnails/19_broken_ass.jpeg'
import thumbnail20 from './thumbnails/20_military-main-min.jpg'
import thumbnail21 from './thumbnails/21_end_of_time-min.jpg'

//these are side images 
import related1 from './realted_thumbnails/1.1_goddess_side-min.jpg'
import related2 from './realted_thumbnails/2.1_emergence_side-min.jpg'
import related3 from './realted_thumbnails/3.1_essence_side-min.jpg'
import related4 from './realted_thumbnails/4.1_head_LR_front-min.jpg'
import related4_1 from './realted_thumbnails/4.2_head_LR_side-min.jpg'
import related5 from './realted_thumbnails/5.1_man_woman_blue_close-min.jpg'
import related9 from './realted_thumbnails/9.1_man_woman_related.jpg'
import related10 from './realted_thumbnails/10.1_optism-min.jpg'
import related11 from './realted_thumbnails/11.1_spiral-min.jpg'
import related13 from './realted_thumbnails/13_sail_dream_other.jpg'
import related14 from './realted_thumbnails/14.1_closet_zoom.jpg'
import related14_1 from './realted_thumbnails/14.2_closet_angle.jpg'
import related15 from './realted_thumbnails/15.1_thin_main_forged_face-min.jpg'
import related15_1 from './realted_thumbnails/15.2_thin_main_forged_face-min.jpg'
import related16 from './realted_thumbnails/16.1_red_heart_side.jpg'
import related17_1 from './realted_thumbnails/17_1_extra.jpg'
import related17_2 from './realted_thumbnails/17_2_extra.jpg'
import related17_3 from './realted_thumbnails/17_3_extra.jpg'
import related17_4 from './realted_thumbnails/17_4_extra.jpg'
import related18 from './realted_thumbnails/18_1_red_flower.jpg'
import related20_1 from './realted_thumbnails/20.1_military-side-1-min.jpg'
import related20_2 from './realted_thumbnails/20.2_military-side-2-min.jpg'
import related20_3 from './realted_thumbnails/20.3_military-side-3-min.jpg'
import related20_4 from './realted_thumbnails/20.4_military-side-4-min.jpg'
import related21_1 from './realted_thumbnails/21.1_end_of_time-min.jpg'
import related21_2 from './realted_thumbnails/21.2_end_of_time-min.jpg'
import related21_3 from './realted_thumbnails/21.3_end_of_time-min.jpg'
import related21_4 from './realted_thumbnails/21.4_end_of_time-min.jpg'

function NewWork() {


    // const [relatedImage, setRelatedImages] = useState([related1]);
    // const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    // const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    // const [displayImageHeading, setDisplayImageHeading] = useState('Goddess');
    // const [displayImageSize, setDisplayImageSize] = useState('24" x 16" x 12"');
    // const [displayImageMaterial, setDisplayImageMaterial] = useState('Stainless Steel, Arcylic, Coppper');
    const relatedImages1 = [related1]
    const relatedImages2 = [related2]
    const relatedImages3 = [related3]
    const relatedImages4 = [related4, related4_1]
    const relatedImages5 = [related5]
    const relatedImages6 = []
    const relatedImages7 = []
    const relatedImages8 = []
    const relatedImages9 = [related9]
    const relatedImages10 = [related10]
    const relatedImages11 = [related11]
    const relatedImages12 = []
    const relatedImages13 = [related13]
    const relatedImages14 = [related14, related14_1]
    const relatedImages15 = [related15, related15_1]
    const relatedImages16 = [related16]
    const relatedImages17 = [related17_1, related17_4, related17_3, related17_2]
    const relatedImages18 = [related18]
    const relatedImages19 = []
    const relatedImages20 = [related20_1, related20_2, related20_3, related20_4]
    const relatedImages21 = [related21_1, related21_3, related21_4, related21_2]

    const [relatedImage, setRelatedImages] = useState(relatedImages21);
    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail21);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(21);
    const [displayImageHeading, setDisplayImageHeading] = useState('Contemplating the End Of Time');
    const [displayImageSize, setDisplayImageSize] = useState('9" x 9" x 8.5"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Steel, Brass, Copper, Resin');
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            const progressBar = document.querySelector(".loadingContainer");
            const mainImageGrid = document.querySelector(".mainNewWorkContainer");
            if (progressBar) progressBar.style.display = "none";
            if (mainImageGrid) mainImageGrid.style.display = "block";
        }, 1200);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);


    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        var new_related;
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)
        if (mainImageOnDisplay == 1 && element.target.id == 0) {
            setDisplayImageHeading('Goddess (Side View)')
            setCurrentDisplayImage(related1)
            new_related = relatedImages1 //this is the array of related images
            new_related.push(thumbnail1)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 2 && element.target.id == 0) {
            setDisplayImageHeading('Emergence (Side View)')
            setCurrentDisplayImage(related2)
            new_related = relatedImages2 //this is the array of related images
            new_related.push(thumbnail2)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 3 && element.target.id == 0) {
            setDisplayImageHeading('Essence (Close Up)')
            setCurrentDisplayImage(related3)
            new_related = relatedImages3 //this is the array of related images
            new_related.push(thumbnail3)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 4 && element.target.id == 0) {
            setDisplayImageHeading('Head (3/4 View Close Up)')
            setCurrentDisplayImage(related4)
            new_related = relatedImages4 //this is the array of related images
            new_related.push(thumbnail4)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 4 && element.target.id == 1) {
            setDisplayImageHeading('Head (Left Side View)')
            setCurrentDisplayImage(related4_1)
            new_related = relatedImages4 //this is the array of related images
            new_related.push(thumbnail4)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 5 && element.target.id == 0) {
            setDisplayImageHeading('Man Women Blue (Close Up)')
            setCurrentDisplayImage(related5)
            new_related = relatedImages5 //this is the array of related images
            new_related.push(thumbnail5)
            setRelatedImages(new_related)
        }
        //image 9
        else if (mainImageOnDisplay == 9 && element.target.id == 0) {
            setDisplayImageHeading('Man Woman')
            setCurrentDisplayImage(related9)
            new_related = relatedImages9 //this is the array of related images
            new_related.push(thumbnail9)
            setRelatedImages(new_related)
        }
        //image 10
        else if (mainImageOnDisplay == 10 && element.target.id == 0) {
            setDisplayImageHeading('Optism')
            setCurrentDisplayImage(related10)
            new_related = relatedImages10 //this is the array of related images
            new_related.push(thumbnail10)
            setRelatedImages(new_related)
        }
        //image 11
        else if (mainImageOnDisplay == 11 && element.target.id == 0) {
            setDisplayImageHeading('Spiral Wave')
            setCurrentDisplayImage(related11)
            new_related = relatedImages11 //this is the array of related images
            new_related.push(thumbnail11)
            setRelatedImages(new_related)
        }

        //image 14 0 and 14 1 close up and alternate view
        else if (mainImageOnDisplay == 14 && element.target.id == 0) {
            setDisplayImageHeading('Closet (Close Up)')
            setCurrentDisplayImage(related14)
            new_related = relatedImages14 //this is the array of related images
            new_related.push(thumbnail14)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 14 && element.target.id == 1) {
            setDisplayImageHeading('Closet (Alternate View)')
            setCurrentDisplayImage(related14_1)
            new_related = relatedImages14 //this is the array of related images
            new_related.push(thumbnail14)
            setRelatedImages(new_related)
        }
        //image 15 0 and 15 1 close up and alternate view
        else if (mainImageOnDisplay == 15 && element.target.id == 0) {
            setDisplayImageHeading('Who do you think you are? (Close Up)')
            setCurrentDisplayImage(related15)
            new_related = relatedImages15 //this is the array of related images
            new_related.push(thumbnail15)
            setRelatedImages(new_related)
        }
        else if (mainImageOnDisplay == 15 && element.target.id == 1) {
            setDisplayImageHeading('Who do you think you are? (Close Up)')
            setCurrentDisplayImage(related15_1)
            new_related = relatedImages15 //this is the array of related images
            new_related.push(thumbnail15)
            setRelatedImages(new_related)
        }
        //image 16 0 alternate view
        else if (mainImageOnDisplay == 16 && element.target.id == 0) {
            setDisplayImageHeading('Red Heart (Alternate View)')
            setCurrentDisplayImage(related16)
            new_related = relatedImages16 //this is the array of related images
            new_related.push(thumbnail16)
            setRelatedImages(new_related)
        }
        //image 17 0 and 17 1 alternate view
        else if (mainImageOnDisplay == 17 && element.target.id == 0) {
            setDisplayImageHeading('Family (Alternate View)')
            setCurrentDisplayImage(related17_1)
            setRelatedImages(relatedImages17)
        }
        else if (mainImageOnDisplay == 18 && element.target.id == 0) {
            setDisplayImageHeading('Red/Black (Side View)')
            setCurrentDisplayImage(related18)
            new_related = relatedImages18 //this is the array of related images
            new_related.push(thumbnail18)
            setRelatedImages(new_related)
        }
    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src

        setRelatedImages([])

        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Goddess')
                setDisplayImageSize('24" x 16" x 12"')
                setDisplayImageMaterial('Stainless Steel, Arcylic, Coppper')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Emergence')
                setDisplayImageSize('11" x 7" x 28.5"')
                setDisplayImageMaterial('Steel, Aluminium')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Essence')
                setDisplayImageSize('24" x 12" x 12"')
                setDisplayImageMaterial('Stainless Steel, Steel, Granite, Paint')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Head (Right Side View)')
                setDisplayImageSize('26" x 15" x 15"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Man Women Blue')
                setDisplayImageSize('15" x 32" x 61"')
                setDisplayImageMaterial('Powder Coated Steel, Granite Base')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('Chaos - Bust')
                setDisplayImageSize('18" x 18" x 24"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '7':
                console.log('came to image 7')
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('Coral')
                setDisplayImageSize('10" x 18" x 8"')
                setDisplayImageMaterial('Steel, Brass, Stone')
                const mainImage = document.getElementsByClassName('displayImageContainer')
                mainImage[0].style.display = 'block'
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('Spiral Reduction')
                setDisplayImageSize('18.5" x 18.5" x 4.25"')
                setDisplayImageMaterial('Alimunium, Walnut')
                break;
            case '9':
                setRelatedImages(relatedImages9)
                setCurrentDisplayImage(thumbnail9)
                setMainImageOnDisplay(9)
                setDisplayImageHeading('Man Woman')
                setDisplayImageSize('6" x 6" x 37"')
                setDisplayImageMaterial('Steel, Acid Etched, Gold Vinyl')
                break;
            case '10':
                setRelatedImages(relatedImages10)
                setCurrentDisplayImage(thumbnail10)
                setMainImageOnDisplay(10)
                setDisplayImageHeading('Optimism')
                setDisplayImageSize('33.5" x 11.5" x 2"')
                setDisplayImageMaterial('Stainless brass finish')
                break;
            case '11':
                setRelatedImages(relatedImages11)
                setCurrentDisplayImage(thumbnail11)
                setMainImageOnDisplay(11)
                setDisplayImageHeading('Spiral Wave')
                setDisplayImageSize('10" x 11" x 6"')
                setDisplayImageMaterial('Steel. Hot Acid Patina')
                break;
            case '12':
                setRelatedImages(relatedImages12)
                setCurrentDisplayImage(thumbnail12)
                setMainImageOnDisplay(12)
                setDisplayImageHeading('Keep on Truckin')
                setDisplayImageSize('86" x 19" x 4"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '13':
                setRelatedImages(relatedImages13)
                setCurrentDisplayImage(thumbnail13)
                setMainImageOnDisplay(13)
                setDisplayImageHeading('Sail dream')
                setDisplayImageSize('43" x 24" x 69"')
                setDisplayImageMaterial('Aluminum')
                break;
            case '14':
                setRelatedImages(relatedImages14)
                setCurrentDisplayImage(thumbnail14)
                setMainImageOnDisplay(14)
                setDisplayImageHeading('Closet')
                setDisplayImageSize('25" x 15" x 32"')
                setDisplayImageMaterial('Mixed Media')
                break;
            case '15':
                setRelatedImages(relatedImages15)
                setCurrentDisplayImage(thumbnail15)
                setMainImageOnDisplay(15)
                setDisplayImageHeading('Who do you think you are?')
                setDisplayImageSize('19" x 2.5" x 2.5"')
                setDisplayImageMaterial('Forged and Torched Steel')
                break;
            case '16':
                setRelatedImages(relatedImages16)
                setCurrentDisplayImage(thumbnail16)
                setMainImageOnDisplay(16)
                setDisplayImageHeading('Red Heart')
                setDisplayImageSize('14" x 6" x 3"')
                setDisplayImageMaterial('Formed Stainless Steel on Walnut base')
                break;
            case '17':
                setRelatedImages(relatedImages17)
                setCurrentDisplayImage(thumbnail17)
                setMainImageOnDisplay(17)
                setDisplayImageHeading('Family')
                setDisplayImageSize('22" x 8" x 4"')
                setDisplayImageMaterial('Steel on marble base')
                break;
            case '18':
                setRelatedImages(relatedImages18)
                setCurrentDisplayImage(thumbnail18)
                setMainImageOnDisplay(18)
                setDisplayImageHeading('Red/Black')
                setDisplayImageSize('18" x 20"')
                setDisplayImageMaterial('Painted Steel')
                break;
            case '19':
                setRelatedImages(relatedImages19)
                setCurrentDisplayImage(thumbnail19)
                setMainImageOnDisplay(19)
                setDisplayImageHeading('She Broke Her Ass For You 2')
                setDisplayImageSize('10” x 3” x 2.5"')
                setDisplayImageMaterial('Various materials')
                break;
            case '20':
                setRelatedImages(relatedImages20)
                setCurrentDisplayImage(thumbnail20)
                setMainImageOnDisplay(20)
                setDisplayImageHeading('Military Industrial Complex')
                setDisplayImageSize('8" x 8" x 6"')
                setDisplayImageMaterial('Steel, Stainless Steel, Resin')
                break;
            case '21':
                setRelatedImages(relatedImages21)
                setCurrentDisplayImage(thumbnail21)
                setMainImageOnDisplay(21)
                setDisplayImageHeading('Contemplating the End Of Time')
                setDisplayImageSize('9" x 9" x 8.5"')
                setDisplayImageMaterial('Steel, Brass, Copper, Resin')
                break;
            default:
                setRelatedImages([])
                break;
        }

    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        console.log("iamge", image)
        //handle full screen for all browsers and mobile devices
        if (image.requestFullscreen) {
            image.requestFullscreen();
        }
        else if (image.webkitRequestFullscreen) {
            image.webkitRequestFullscreen();
        }
        else if (image.mozRequestFullScreen) {
            image.mozRequestFullScreen();
        }
        else if (image.msRequestFullscreen) {
            image.msRequestFullscreen();
        }
        setFullScreen(true)
    }


    return (

        <div className="newWork">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>

            {/*This is for DESKTOP VIEW*/}
            <div className='mainNewWorkContainer'>
                <div className='newWorkContainer'>
                    <div className='firstContainer'>
                        <div className='row-tiny'>
                            <img className='imageCard' id='21' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail21} alt='newWork'></img>
                            <img className='imageCard' id='20' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail20} alt='newWork'></img>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail1} alt='newWork'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail3} alt='newWork'></img>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail4} alt='newWork'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail5} alt='newWork'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail6} alt='newWork'></img>
                            <img className='imageCard' id='7' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail7} alt='newWork'></img>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail8} alt='newWork'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='15' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail15} alt='newWork'></img>
                            <img className='imageCard' id='9' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail9} alt='newWork'></img>
                            <img className='imageCard' id='10' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail10} alt='newWork'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='13' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail13} alt='newWork'></img>
                            <img className='imageCard' id='14' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail14} alt='newWork'></img>
                            <img className='imageCard' id='16' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail16} alt='newWork'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail2} alt='newWork'></img>
                            <img className='imageCard' id='12' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail12} alt='newWork'></img>
                            <img className='imageCard' id='17' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail17} alt='newWork'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='19' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail19} alt='newWork'></img>
                            <img className='imageCard' id='18' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail18} alt='newWork'></img>
                            <img className='imageCard' id='11' onMouseOver={(e) => updateImage(e)} onClick={(e) => updateImage(e)} src={thumbnail11} alt='newWork'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>
                        {(mainImageOnDisplay != 7) &&
                            <div className='displayImageContainer'>
                                <div className='displayImageOne'>
                                    <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                                </div>
                                <div className='displayImageTwo'>
                                    <div className='related_images'>
                                        {relatedImage.map((url, index) => {
                                            return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                        })}
                                    </div>
                                    <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                    <div id='display_image_information'>
                                        <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                        <h6 id='display_image_size'>{displayImageSize}</h6>
                                        <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                    </div>
                                </div>
                            </div>
                        }
                        {(mainImageOnDisplay == 7) &&
                            <div className='displayImageNonFlex'>
                                <div className='displayImageOneFlex'>
                                    <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                                    <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                </div>
                                <div className='displayImageTwo'>
                                    <div className='related_images'>
                                        {relatedImage.map((url, index) => {
                                            return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                        })}
                                    </div>
                                    <div id='display_image_information'>
                                        <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                        <p id='display_image_size'>{displayImageSize}</p>
                                        <p id='display_image_material'>{displayImageMaterial}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_21' src={thumbnail21} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_20' src={thumbnail20} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_18' src={thumbnail18} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_16' src={thumbnail16} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail8} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_9' src={thumbnail9} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_10' src={thumbnail10} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_11' src={thumbnail11} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_13' src={thumbnail13} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>

                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_13' src={thumbnail13} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_14' src={thumbnail14} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_15' src={thumbnail15} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_12' src={thumbnail12} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_7' src={thumbnail7} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_17' src={thumbnail17} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>

                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_19' src={thumbnail19} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img>
                    {/* <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='newWork'></img> */}
                </div>
            </div>

        </div>

    )

}

export default NewWork