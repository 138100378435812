import React from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './Gallery3.css'
import '../../styles/style.css'
import 'react-circular-progressbar/dist/styles.css';

//thumbnails
import thumbnail1 from './thumbnails/1_spiral-min.jpg'
import thumbnail2 from './thumbnails/2_ascension-min.jpg'
import thumbnail3 from './thumbnails/3_keyway-min.jpg'
import thumbnail4 from './thumbnails/4_elephant-min.jpg'
import thumbnail5 from './thumbnails/5_sail_interior-min.jpg'
import thumbnail6 from './thumbnails/6_leaves-min.jpg'
import thumbnail7 from './thumbnails/7_whole_fan-min.jpg'
import thumbnail8 from './thumbnails/8_three_forms_min.jpg'
import thumbnail9 from './thumbnails/9_tumbling_light.jpg'
import thumbnail10 from './thumbnails/10_Boogie_Woogie.jpg'
import thumbnail11 from './thumbnails/11_ladder_to_heaven.jpg'
import thumbnail12 from './thumbnails/12_movie.jpg'
import thumbnail13 from './thumbnails/13_three_foarms_two.png'
import thumbnail14 from './thumbnails/14_nude_descending-min.jpeg'
import thumbnail15 from './thumbnails/15_sail_dream-min.jpg'
import thumbnail16 from './thumbnails/16_red_flower.jpg'


import realtedThumb5 from './related_thumbs/5_1_sail_small-min.jpg'
import relatedThumb8 from './related_thumbs/10_1_three_forms_related.jpeg'
import relatedThumb8_1 from './related_thumbs/10_2_three_forms_related.jpg'
import relatedThumb10 from './related_thumbs/10_boggie_closeup.jpg'
import relatedThumb15 from './related_thumbs/15_sail_dream_other.jpg'
import relatedThumb16 from './related_thumbs/16_1_red_flower.jpg'

function GalleryThree() {

    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('Spiral Reduction');
    const [displayImageSize, setDisplayImageSize] = useState('18.5" x 18.5" x 4.25"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Aluminium, Walnut');
    const [fullScreen, setFullScreen] = useState(false);

    const relatedImages1 = []
    const relatedImages2 = []
    const relatedImages3 = []
    const relatedImages4 = []
    const relatedImages5 = [realtedThumb5]
    const relatedImages6 = []
    const relatedImages7 = []
    const relatedImages8 = [relatedThumb8]
    const relatedImages9 = []
    const relatedImages10 = [relatedThumb10]
    const relatedImages11 = []
    const relatedImages12 = []
    const relatedImages13 = [relatedThumb8_1]
    const relatedImages14 = []
    const relatedImages15 = [relatedThumb15]
    const relatedImages16 = [relatedThumb16]

    const [relatedImage, setRelatedImages] = useState(relatedImages1);

    useEffect(() => {
        const timer = setTimeout(() => {
            const progressBar = document.querySelector(".loadingContainer");
            const mainImageGrid = document.querySelector(".mainGalleryThreeContainer");
            if (progressBar) progressBar.style.display = "none";
            if (mainImageGrid) mainImageGrid.style.display = "block";
        }, 1200);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    /*jslint eqeq: true*/
    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)
        if (mainImageOnDisplay == 5 && element.target.id == 0) {
            setDisplayImageHeading('Sail Dream (Small Version)')
        }
    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src

        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Spiral Reduction')
                setDisplayImageSize('18.5" x 18.5" x 4.25"')
                setDisplayImageMaterial('Aluminium, Walnut')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Ascension')
                setDisplayImageSize('28" x 9" x 5"')
                setDisplayImageMaterial('Stainless Steel, Stone')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Keyway')
                setDisplayImageSize('32" x 8 1/4" x 3/8"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Elephant')
                setDisplayImageSize('52" x 19" x 6.5"')
                setDisplayImageMaterial('Powder Coated Steel, Granite Base')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Sail Dream (Small Version)')
                setDisplayImageSize('Various Sizes')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('Leaves')
                setDisplayImageSize('30" x 12" x 1/2"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '7':
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('Fan')
                setDisplayImageSize('14" x 16" x 2 3/8"')
                setDisplayImageMaterial('Stainless Steel on Wooden Base, Brushed and Plasma Cut Finish')
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('Three Forms')
                setDisplayImageSize('14" x 6" x 6"')
                setDisplayImageMaterial('Stainless Steel on Marble Base')
                break;
            case '9':
                setRelatedImages(relatedImages9)
                setCurrentDisplayImage(thumbnail9)
                setMainImageOnDisplay(9)
                setDisplayImageHeading('Tumbling Light')
                setDisplayImageSize('4" x 10" x 8"')
                setDisplayImageMaterial('Brushed Stainless Steel')
                break;
            case '10':
                setRelatedImages(relatedImages10)
                setCurrentDisplayImage(thumbnail10)
                setMainImageOnDisplay(10)
                setDisplayImageHeading('Boogie Woogie')
                setDisplayImageSize('44" x 1/2"')
                setDisplayImageMaterial('Steel Flame Acid Finish')
                break;
            case '11':
                setRelatedImages(relatedImages11)
                setCurrentDisplayImage(thumbnail11)
                setMainImageOnDisplay(11)
                setDisplayImageHeading('Ladder to Heaven')
                setDisplayImageSize('18" x 7.25" x 1"')
                setDisplayImageMaterial('Stainless Steel brushed finish on wood base')
                break;
            case '12':
                setRelatedImages(relatedImages12)
                setCurrentDisplayImage(thumbnail12)
                setMainImageOnDisplay(12)
                setDisplayImageHeading('Movie')
                setDisplayImageSize('29" x 13" x 2"')
                setDisplayImageMaterial('Stainless Steel hand ground finish')
                break;
            case '13':
                setRelatedImages(relatedImages13)
                setCurrentDisplayImage(thumbnail13)
                setMainImageOnDisplay(13)
                setDisplayImageHeading('Three Forms Two')
                setDisplayImageSize('31" x 6" x 6"')
                setDisplayImageMaterial('Stainless Steel on Marble Base')
                break;
            case '14':
                setRelatedImages(relatedImages14)
                setCurrentDisplayImage(thumbnail14)
                setMainImageOnDisplay(14)
                setDisplayImageHeading('Nude Descending')
                setDisplayImageSize('61" x 31" x 1/2"')
                setDisplayImageMaterial('Stainless Steel, Brushed Finish')
                break;
            case '15':
                setRelatedImages(relatedImages15)
                setCurrentDisplayImage(thumbnail15)
                setMainImageOnDisplay(15)
                setDisplayImageHeading('Sail Dream')
                setDisplayImageSize('43" x 24" x 69"')
                setDisplayImageMaterial('Aluminum')
                break;
            case '16':
                setRelatedImages(relatedImages16)
                setCurrentDisplayImage(thumbnail16)
                setMainImageOnDisplay(16)
                setDisplayImageHeading('Red/Black')
                setDisplayImageSize('18" x 20"')
                setDisplayImageMaterial('Painted Steel')
                break;
            default:
                break;
        }
    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return <div>
        <div className="galleryThree">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textColor: '#000080',
                                    textSize: '12px',
                                    pathTransitionDuration: 0.5,
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>


            {/*This is for DESKTOP VIEW*/}
            <div className='mainGalleryThreeContainer'>
                <div className='galleryThreeContainer'>
                    <div className='firstContainer'>
                        <div className='row-small'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} src={thumbnail1} alt='galleryThree'></img>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='galleryThree'></img>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='galleryThree'></img>
                        </div>
                        <div className='row-small'>
                            <img className='imageCard' id='14' onMouseOver={(e) => updateImage(e)} src={thumbnail14} alt='galleryThree'></img>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} src={thumbnail6} alt='galleryThree'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='galleryThree'></img>
                        </div>
                        <div className='row-small'>
                            <img className='imageCard' id='10' onMouseOver={(e) => updateImage(e)} src={thumbnail10} alt='galleryThree'></img>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} src={thumbnail8} alt='galleryThree'></img>
                            <img className='imageCard' id='13' onMouseOver={(e) => updateImage(e)} src={thumbnail13} alt='galleryThree'></img>
                        </div>
                        <div className='row-small'>
                            <img className='imageCard' id='9' onMouseOver={(e) => updateImage(e)} src={thumbnail9} alt='galleryThree'></img>
                            <img className='imageCard' id='11' onMouseOver={(e) => updateImage(e)} src={thumbnail11} alt='galleryThree'></img>
                            <img className='imageCard' id='12' onMouseOver={(e) => updateImage(e)} src={thumbnail12} alt='galleryThree'></img>

                        </div>
                        <div className='row-small'>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} src={thumbnail4} alt='galleryThree'></img>
                            <img className='imageCard' id='7' onMouseOver={(e) => updateImage(e)} src={thumbnail7} alt='galleryThree'></img>
                        </div>
                        <div className='row-small'>
                            <img className='imageCard' id='15' onMouseOver={(e) => updateImage(e)} src={thumbnail15} alt='galleryThree'></img>
                            <img className='imageCard' id='16' onMouseOver={(e) => updateImage(e)} src={thumbnail16} alt='galleryThree'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>
                        <div className='displayImageContainer'>
                            <div className='displayImageOne'>
                                <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                            </div>
                            <div className='displayImageTwo'>
                                <div className='related_images'>
                                    {relatedImage.map((url, index) => {
                                        return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                    })}
                                </div>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <h6 id='display_image_size'>{displayImageSize}</h6>
                                    <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_7' src={thumbnail7} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail8} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_9' src={thumbnail9} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_10' src={thumbnail10} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_11' src={thumbnail11} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_12' src={thumbnail12} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_13' src={thumbnail13} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_14' src={thumbnail14} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                    <img className='imageCardMobile' id='mobile_15' src={thumbnail15} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_16' src={thumbnail16} onClick={(e) => openFullScreenMobile(e)} alt='galleryThree'></img>
                </div>
            </div>
        </div>
    </div>;
}

export default GalleryThree;
